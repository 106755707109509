import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import Cookies from '../components/slides/cookies/Cookies';

const Tos = () => {
  return (
    <Layout>
      <SEO
        title="Cookies | Deadline Funnel"
        description="Cookies"
      />
      <Cookies />
    </Layout>
  )
};

export default Tos;