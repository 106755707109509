import React, { useState, useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";

import styles from "./Cookies.module.css";

import Container from "../../../blocks/Container";

import axios from "axios";

const Cookies = () => {
  const [inEU, setInEU] = useState(false);

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setInEU(data.in_eu);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/cookies.md/" } }
      ) {
        edges {
          node {
            html
          }
        }
      }
    }
  `);

  const { html } = data.allMarkdownRemark.edges[0].node;

  return (
    <div className={styles.cookies}>
      <Container type="big">
        <div class="row mb-5">
          <div class="col-12 text-center">
            <h3 class="white">Cookies</h3>
          </div>
        </div>
        <br />
        <div className={inEU ? '' : 'hide'} dangerouslySetInnerHTML={{ __html: html }} />
        {!inEU &&
        <React.Fragment>
          <p>This website uses cookies. We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services.</p>
          <p>Cookies are small text files that can be used by websites to make a user's experience more efficient.</p>
          </React.Fragment> 
        }
        <br />
        <h5>Related Content</h5>
        <ul>
          <li><a href="/tos">Terms of Service / Privacy Policy</a></li>
          <li><a href="/subprocessors">Subprocessors</a></li>
          <li><a href="/security">Security</a></li>
          <li><a href="/dpa">DPA / SCC</a></li>
          <li><a href="/gdpr">GDPR</a></li>
          <li><a href="/cookies">Cookies</a></li>
        </ul>
      </Container>
    </div>
  );
};

export default Cookies;
